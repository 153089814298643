<template>
  <mds-layout-grid>
    <filter-section />
    <header-section />
    <TableSectionEM />
  </mds-layout-grid>
</template>

<script>
import FilterSection from '@/components/collection-exchange-monitor/filters/FilterSection';
import HeaderSection from '@/components/collection-exchange-monitor/header/HeaderSection';
import TableSectionEM from '@/components/collection-exchange-monitor/TableSectionEM.vue';
export default {
  name: 'CollectionPageEM',
  components: {
    FilterSection,
    HeaderSection,
    TableSectionEM,
  },
};
</script>
