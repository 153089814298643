<template>
  <mds-row
    align-horizontal="space-between"
    class="filter"
  >
    <mds-col :cols="6">
      <mds-search-field
        placeholder="Search by Name or Company Id"
        class="filter__search"
        label="Search"
        @input.native="fetchProviders"
      />
    </mds-col>
    <mds-col :cols="6">
      <mds-button-group
        :content="buttonGroupContent"
        class="filter__status"
        @mds-button-group-item-active="setActiveItem"
      />
    </mds-col>
  </mds-row>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import MdsButtonGroup from '@mds/button-group';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButtonGroup,
  },
  data () {
    return {
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true },
        { id: '1', text: 'Active', value: true, active: false },
        { id: '2', text: 'Inactive', value: false, active: false }
      ],
    };
  },
  methods: {
    fetchProviders: debounce(async function ({ target: { value } }) {
      this.$store.commit(`${MODULE.PROVIDERS}/${COMMON.UPDATE_PARAM}`, { name: 'nameOrCompanyId', value });
      await this.$store.dispatch(`${MODULE.PROVIDERS}/${COMMON.FETCH_DATA}`, { shouldReset: true });
    }, 1000),
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.$store.commit(`${MODULE.PROVIDERS}/${COMMON.UPDATE_PARAM}`, { name: 'enabled', value: item.value });
          this.$store.dispatch(`${MODULE.PROVIDERS}/${COMMON.FETCH_DATA}`, { shouldReset: true });
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &__search {
    width: 280px;
  }
  &__status {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
