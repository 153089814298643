<template>
  <mds-layout-grid>
    <filter-section />
    <table-section />
  </mds-layout-grid>
</template>

<script>
import FilterSection from '@/components/exchange-monitor/filters/FilterSection';
import TableSection from '@/components/exchange-monitor/table/TableSection';

export default {
  name: 'ProvidersPageEM',
  components: {
    FilterSection,
    TableSection,
  },
};
</script>
