<template>
  <div class="parsers-table">
    <mds-modal
      v-model="isModalVisible"
      width="1200px"
      fullscreen
      class="modal_detail"
    >
      <mds-section
        border="none"
        :title="collectionSelected.name"
        bold
      >
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="icon-only"
              icon="remove"
              @click="isModalVisible=!isModalVisible"
            >
              Close Modal
            </mds-button>
          </mds-button-container>
        </template>
        <crawled-documents
          v-if="isCollectionSelected"
          :collection-selected="collectionSelected"
          @updateModalIsVisible="updateModalIsVisible"
        />
      </mds-section>
    </mds-modal>
    <mds-modal
      v-model="isModalRunningVisible"
      width="600px"
      action-required
      class="schedule-job__datepicker"
    >
      <mds-section
        border-position="bottom"
        bold
      >
        <template #mds-section-title>
          <span id="title-id">{{ $t(`exchangeMonitors.sheduleJobNowTitle`) }}</span>
        </template>
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              @click="toggleModalRunning"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              :loading="isLoading"
              :disabled="isLoading || !startDate"
              @click="scheduleJobNow(); toggleModalRunning()"
            >
              Run
            </mds-button>
          </mds-button-container>
        </template>
        {{ $t(`exchangeMonitors.sheduleJobNow`) }}
        <mds-date-picker
          class="schedule-job__startdate"
          :value="startDate"
          :min-max-dates="minMaxDates"
          :label="$t(`collectionPageEM.startDateLabel`)"
          @input="updateCurrentDate"
        />
      </mds-section>
    </mds-modal>
    <mds-table row-hover>
      <mds-thead>
        <mds-th
          v-for="header in $options.headers"
          :key="header.fieldName"
          :style="header.style"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <template
          v-for="(row, index) in parsersCopy"
        >
          <mds-tr
            :key="index"
            type="expandable"
            :expanded="row.expanded"
            :aria-controls="index+1"
            @click.native="toggleRow(index)"
            @keyup.enter.native="toggleRow(index)"
          >
            <mds-td
              v-for="(header, i) in $options.headers"
              :key="i"
              :right-aligned="header.align === 'right'"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'isActive'">
                <state-tag
                  :status="row[header.fieldName] ? 'ACTIVE' : 'INACTIVE'"
                  :color="getTagColor(row[header.fieldName])"
                />
              </template>
              <template v-else-if="header.fieldName === 'status'">
                <state-tag
                  :status="row[header.fieldName]"
                  :color="getTagColorStage(row[header.fieldName])"
                />
              </template>
              <template v-else-if="header.fieldName === 'url'">
                <a
                  :href="row[header.fieldName]"
                  target="_blank"
                > {{ row[header.fieldName] }}</a>
              </template>
              <template v-else-if="header.fieldName === 'lastExecutedDate' || header.fieldName === 'createdAt' || header.fieldName === 'crawlingTimeframeStart'">
                {{ row[header.fieldName] | formatDate }}
              </template>
              <template v-else-if="header.fieldName === 'actions'">
                <mds-button
                  variation="icon-only"
                  icon="eye"
                  text="View"
                  @click="toggleModal($event, row)"
                />
                <mds-button
                  variation="icon-only"
                  icon="triangle-fill-right"
                  text="Run"
                  :disabled="row.status === 'SCHEDULED' || row.status === 'RUNNING' || row.running"
                  @click="toggleModalRunning(), setParserToExecute($event, row, index)"
                />
              </template>
              <template v-else>
                {{ row[header.fieldName] }}
              </template>
            </mds-td>
          </mds-tr>
          <mds-tr
            v-if="row.expanded"
            :id="index+1"
            :key="`additional-content-${index}`"
            type="container"
          >
            <mds-td :colspan="$options.headers.length">
              <section
                :aria-label="'Additional information about ' + row.name"
                class="ex-expanded-content"
              >
                <LastExecutionsTable :last-executions="row.lastExecutions" />
              </section>
            </mds-td>
          </mds-tr>
        </template>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import { TABLE_HEADERS, REFRESH_INTERVAL_IN_SECONDS } from '@/constants/parsers.constant';
import ROUTE from '@/constants/routes.constant';
import { STAGES, STATUS } from '@/constants/status-tags-colors.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import MdsDatePicker from '@mds/date-picker';
import LastExecutionsTable from '@/components/collection-exchange-monitor/table/parsers/LastExecutionsTable';
import CrawledDocuments from '@/components/collection-exchange-monitor/crawled-documents/CrawledDocuments';
import cloneDeep from 'lodash.clonedeep';
import StateTag from '@/components/ui/StateTag';
import { formatDate, textFormatter, formatDateToYMD } from '@/utils/global.util';
import { scheduleJobNow } from '@/services/api/exchange-monitors.service';

const minMaxDates = {
  min: new Date(1999, 1, 1),
  max: new Date(),
};

export default {
  name: 'ParserTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSection,
    MdsDatePicker,
    LastExecutionsTable,
    CrawledDocuments,
    StateTag,
  },
  filters: {
    formatDate,
    textFormatter,
  },
  props: {
    parsers: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      parsersCopy: cloneDeep(this.parsers),
      lastExecutions: [],
      isModalVisible: false,
      isModalRunningVisible: false,
      isLoading: false,
      collectionSelected: {},
      startDate: null,
      event: null,
      minMaxDates,
      minMaxEndDates: {
        ...minMaxDates,
      },
      indexSelected: null,
    };
  },
  computed: {
    isCollectionSelected () {
      return this.collectionSelected && Object.keys(this.collectionSelected).length > 0;
    },
  },
  watch: {
    parsers: {
      handler () {
        this.parsersCopy = cloneDeep(this.parsers);
      },
      deep: true,
    },
  },
  headers: TABLE_HEADERS,
  route: ROUTE,
  methods: {
    async toggleRow (index) {
      const status = this.parsersCopy[index].expanded ? !this.parsersCopy[index].expanded : true;
      this.$set(this.parsersCopy[index], 'expanded', status);
    },
    getTagColor (text) {
      const textFormatted = text ? 'ACTIVE' : 'INACTIVE';
      return STATUS[textFormatted] || '';
    },
    getTagColorStage (text) {
      return STAGES[text] || STATUS[text] || '';
    },
    toggleModal (event, collectionSelected) {
      this.isModalVisible = !this.isModalVisible;
      this.collectionSelected = collectionSelected;
    },
    toggleModalRunning () {
      this.isModalRunningVisible = !this.isModalRunningVisible;
    },
    setParserToExecute (event, collectionSelected, indexSelected) {
      this.collectionSelected = collectionSelected;
      this.indexSelected = indexSelected;
    },
    async scheduleJobNow () {
      this.loading = true;
      const data = {
        parser: this.collectionSelected.parserId,
        startDate: formatDateToYMD(this.startDate),
      };
      const { data: response } = await scheduleJobNow(data);
      this.loading = false;
      if (response) {
        this.$set(this.parsersCopy[this.indexSelected], 'status', 'SCHEDULED');
        this.$set(this.parsersCopy[this.indexSelected], 'running', true);
        setTimeout(() => {
          this.$set(this.parsersCopy[this.indexSelected], 'running', false);
        }, REFRESH_INTERVAL_IN_SECONDS * 1000);
      }
    },
    updateModalIsVisible (isModalVisible) {
      this.isModalVisible = isModalVisible;
    },
    updateCurrentDate (value) {
      this.startDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

::v-deep .modal_detail .mds-modal__wrapper__dap-crawler .mds-modal__content__dap-crawler {
  max-height: 100vh;
  overflow: scroll;
  margin: 30px;

  h2 {
    margin-left: $mds-space-2-and-a-half-x;
  }
}

.schedule-job {
  &__startdate {
    margin-top: $mds-space-2-and-a-half-x;
  }

  &__datepicker {
    ::v-deep .mds-modal__wrapper__dap-crawler .mds-modal__content__dap-crawler {
      overflow: inherit
    }
  }
}
</style>
