<template>
  <mds-layout-grid class="select-app__container">
    <mds-row align-horizontal="center">
      <mds-col
        :cols="4"
        class="select-app__card-container"
      >
        <mds-card
          class="select-app__card"
          title="Web Crawler for Companies"
          caption="Launch Web Crawler for Companies"
          @click.native="redirect(routes.PROVIDERS)"
        >
          <template v-slot:mds-card-supplemental-content>
            <img
              :src="require('@/assets/illustrations/WebCrawlerCompaines.svg')"
              alt="ReferenceData"
            >
          </template>
        </mds-card>
      </mds-col>
      <mds-col
        :cols="4"
        class="select-app__card-container"
      >
        <mds-card
          :class="isActive ? 'select-app__card' : 'select-app__card--disabled'"
          title="Web Crawler for Exchange Monitors"
          caption="Launch Web Crawler for Exchange Monitors"
          @click.native="redirect(routes.PROVIDERS_EM)"
        >
          <template v-slot:mds-card-supplemental-content>
            <img
              :src="require('@/assets/illustrations/WebCrawlerEM.svg')"
              alt="ReferenceData"
            >
          </template>
        </mds-card>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import MdsCard from '@mds/card';
import ROUTES from '@/constants/routes.constant';
import { MODULE, COMMON } from '@/constants/store.constant';
import FEATURE_FLAGS from '@/constants/feature-flags';

export default {
  name: 'SelectAppPage',
  layout: 'Empty',
  components: {
    MdsCard,
  },
  data () {
    return {
      routes: ROUTES,
    };
  },
  computed: {
    isActive () {
      const enableEM = this.$store.getters[`${MODULE.FEATURE_FLAGS}/${COMMON.GET_STATE}`](FEATURE_FLAGS.ENABLE_EM);
      return enableEM;
    },
  },
  methods: {
    redirect (name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-app {
  &__container {
    margin-top: 80px;
  }

  &__card {
    cursor: pointer;

    &-container {
      display: flex;
      justify-content: center;
    }

    &--disabled {
      background-color: $mds-color-neutral-80;
      pointer-events: none;
    }
  }
}
</style>
