export const TABLE_HEADERS = [
  {
    fieldName: 'name',
    text: 'Name',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'provider.translatedName',
  },
  {
    fieldName: 'id',
    text: 'Profile Uuid',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'provider.translatedName',
  },
  {
    fieldName: 'url',
    text: 'URL',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'provider.translatedName',
  },
  {
    fieldName: 'createdAt',
    text: 'Process Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'lastExecutedDate',
    text: 'Process End Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'crawlingTimeframeStart',
    text: 'Crawling Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'duration',
    text: 'Duration (Min)',
    style: {
      width: '10%',
    },
    sortable: true,
  },
  {
    fieldName: 'totalFiles',
    text: 'Total Files',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'frequency',
  },
  {
    fieldName: 'executions',
    text: 'Executions',
    style: {
      width: '7%',
    },
    sortable: true,
    sortableName: 'lastExecutedDate',
  },
  {
    fieldName: 'status',
    text: 'Status',
    sortable: true,
    style: {
      width: '12%',
    },
  },
  {
    fieldName: 'isActive',
    text: 'Is Active',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'nextExecutionDate',
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '10%',
    },
  }
];

export const LAST_EXECUTIONS_HEADERS = [
  {
    fieldName: 'batchId',
    text: 'Batch Id',
    sortable: true,
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'status',
    text: 'Status',
    sortable: true,
    style: {
      width: '7%',
    },
  },
  {
    fieldName: 'stage',
    text: 'Stage',
    sortable: true,
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'foundFiles',
    text: 'S3 Files',
    // sortable: true,
    style: {
      width: '6%',
    },
  },
  {
    fieldName: 'numBrokenLinks',
    text: 'Downloading Errors',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'downloadingErrors',
    text: 'Server Errors',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'error',
    text: 'Error Detail',
    style: {
      width: '22%',
    },
  },
  {
    fieldName: 's3ReportUrl',
    text: 'S3 Report',
    style: {
      width: '6%',
    },
  }
];

export const REFRESH_INTERVAL_IN_SECONDS = 180;
