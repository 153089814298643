<template>
  <mds-row
    align-horizontal="space-between"
    class="filter"
  >
    <mds-col :cols="6">
      <mds-search-field
        v-model="filterText"
        placeholder="Search by Name or Profile Uuid"
        class="filter__search"
        label="Search"
        @input="fetchProvidersEM"
      />
    </mds-col>
    <mds-col
      :cols="6"
      class="filter__status"
    >
      <span class="filter__refresh-label">Refresh</span>
      <mds-button
        variation="icon-only"
        class="filter__refresh"
        icon="refresh"
        type="button"
        text="Refresh"
        :disabled="disableRefresh"
        @click="refreshData"
      />
      <mds-button-group
        :content="buttonGroupContent"
        @mds-button-group-item-active="setActiveItem"
      />
    </mds-col>
  </mds-row>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import MdsButtonGroup from '@mds/button-group';
import { MdsButton } from '@mds/button';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButtonGroup,
    MdsButton,
  },
  data () {
    return {
      filterText: '',
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true },
        { id: '1', text: 'Active', value: true, active: false },
        { id: '2', text: 'Inactive', value: false, active: false }
      ],
      disableRefresh: false,
    };
  },
  methods: {
    fetchProvidersEM: debounce(async function () {
      this.$store.commit(`${MODULE.EXCHANGE_MONITORS}/${COMMON.UPDATE_PARAM}`, { name: 'name', value: this.filterText });
      await this.$store.dispatch(`${MODULE.EXCHANGE_MONITORS}/${COMMON.FILTER_DATA}`, 'name');
    }, 500),
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.$store.commit(`${MODULE.EXCHANGE_MONITORS}/${COMMON.UPDATE_PARAM}`, { name: 'active', value: item.value });
          this.$store.dispatch(`${MODULE.EXCHANGE_MONITORS}/${COMMON.FILTER_DATA}`, 'active');
        };
      });
    },
    async refreshData () {
      this.$store.commit(`${MODULE.EXCHANGE_MONITORS}/${COMMON.UPDATE_PARAM}`, { name: 'name', value: null });
      this.$store.commit(`${MODULE.EXCHANGE_MONITORS}/${COMMON.UPDATE_PARAM}`, { name: 'active', value: null });
      this.disableRefresh = true;
      this.filterText = '';
      this.$store.commit(`${MODULE.EXCHANGE_MONITORS}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
      setTimeout(() => {
        this.disableRefresh = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
.filter {
  &__search {
    width: 280px;
  }
  &__status {
    display: flex;
    justify-content: flex-end;
  }
  &__refresh {
    margin-right: $mds-space-2-x;
  }
  &__refresh-label {
    @include mds-body-text-m();
    margin-right: $mds-space-2-x;
    margin-top: $mds-space-three-quarter-x;
    font-size: $mds-space-1-and-a-half-x;
    font-weight: 600;
  }
}
</style>
