<template>
  <div class="last-executions-table">
    <mds-modal
      v-model="isModalVisible"
      width="1200px"
    >
      <img
        :src="require('@/assets/illustrations/jobExecutionWorkflow.png')"
      >
    </mds-modal>
    <h3>{{ this.$t(`collectionPageEM.lastExecution`) }}</h3>
    <mds-table row-hover>
      <mds-thead>
        <mds-th
          v-for="header in $options.headers"
          :key="header.fieldName"
          :style="header.style"
        >
          {{ header.text }}
          <template v-if="header.fieldName === 'stage'">
            <mds-button
              class="last-executions-table__button"
              variation="icon-only"
              icon="info-circle"
              type="button"
              size="small"
              @click="toggleModal"
            />
          </template>
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <template
          v-for="(row, index) in lastExecutions"
        >
          <mds-tr
            :key="index"
            :aria-controls="index+1"
          >
            <mds-td
              v-for="(header, i) in $options.headers"
              :key="i"
              :right-aligned="header.align === 'right'"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'stage'">
                <state-tag
                  :status="row[header.fieldName]"
                  :color="getTagColor(row[header.fieldName])"
                />
              </template>
              <template v-else-if="header.fieldName === 'status'">
                <state-tag
                  :status="row[header.fieldName]"
                  :color="getTagColor(row[header.fieldName])"
                />
              </template>
              <template v-else-if="header.fieldName === 'endTime'">
                {{ row[header.fieldName] | formatDate }}
              </template>
              <template v-else-if="header.fieldName === 's3ReportUrl'">
                <mds-button
                  variation="icon-only"
                  :icon="row[header.fieldName] && row[header.fieldName] !== '' ? 'eye' : 'eye-crossed'"
                  type="button"
                  text="json"
                  :disabled="!row[header.fieldName] || row[header.fieldName] === ''"
                  @click="openReport(row.batchId)"
                />
              </template>
              <template v-else>
                {{ row[header.fieldName] }}
              </template>
            </mds-td>
          </mds-tr>
        </template>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import { LAST_EXECUTIONS_HEADERS } from '@/constants/parsers.constant';
import { STAGES, STATUS } from '@/constants/status-tags-colors.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import StateTag from '@/components/ui/StateTag';
import { formatDate, textFormatter } from '@/utils/global.util';
import { getAWSReportlURL } from '@/services/api/exchange-monitors.service';

export default {
  name: 'ParserTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsModal,
    StateTag,
  },
  filters: {
    formatDate,
    textFormatter,
  },
  props: {
    lastExecutions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    toggleModal () {
      this.isModalVisible = !this.isModalVisible;
    },
    getTagColor (text) {
      return STAGES[text] || STATUS[text] || '';
    },
    async openReport (awsBatchUuid) {
      const result = await getAWSReportlURL(awsBatchUuid);
      const { data: url } = result;
      url && window.open(url, '_blank');
    },
  },
  headers: LAST_EXECUTIONS_HEADERS,
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.last-executions-table {
  margin: 30px 0 0 30px;
  border: 1px solid $mds-color-neutral-90;
  padding: $mds-space-1-and-a-half-x;

  &__button {
    margin-left: $mds-space-half-x;
    padding-top: 2px;
  }

  th {
      background-color: $mds-color-neutral-90;
  }

  ::v-deep .mds-th__text__dap-crawler {
    display: flex;
  }
  ::v-deep .mds-th__inner__dap-crawler {
    margin-bottom: 10px;
  }
}
</style>
