<template>
  <mds-row>
    <mds-col>
      <mds-button-container right-aligned>
        <router-link :to="{ name: $options.route.PROVIDER }">
          <mds-button
            variation="flat"
            icon="plus"
            text="Add Provider"
          />
        </router-link>
        <mds-button
          variation="flat"
          icon="upload"
          text="Import Providers"
          @click="toggle=!toggle"
        />
      </mds-button-container>
      <store-provider
        v-bind="$options.storeInfo"
        is-paged
      >
        <template v-slot="{ state: providers }">
          <mds-table row-hover>
            <mds-thead>
              <mds-th
                v-for="header in $options.headers"
                :key="header.fieldName"
                :style="header.style"
                v-text="header.text"
              />
            </mds-thead>
            <mds-tbody>
              <mds-tr
                v-for="provider in providers"
                :key="provider.id"
              >
                <mds-td
                  v-for="header in $options.headers"
                  :key="header.fieldName"
                  :style="header.style"
                >
                  <template v-if="header.fieldName === 'homePage'">
                    <a
                      :href="provider[header.fieldName]"
                      target="_blank"
                      v-text="provider[header.fieldName]"
                    />
                  </template>
                  <template v-else-if="header.fieldName === 'links'">
                    {{ provider.links.filter((link) => link.enabled).length }}
                  </template>
                  <template v-else-if="header.fieldName === 'enabled'">
                    <status-tag :status="provider[header.fieldName] ? 'ACTIVE' : 'INACTIVE'" />
                  </template>
                  <template v-else-if="header.fieldName === 'actions'">
                    <mds-button-container>
                      <router-link :to="{ name: $options.route.PROVIDER, params: { id: provider.companyId } }">
                        <mds-button
                          variation="icon-only"
                          icon="create"
                          type="button"
                          text="Edit"
                        />
                      </router-link>
                    </mds-button-container>
                  </template>
                  <template v-else>
                    {{ provider[header.fieldName] }}
                  </template>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
        </template>
      </store-provider>
    </mds-col>
    <mds-modal
      v-model="toggle"
      width="600px"
    >
      <mds-section
        border-position="bottom"
        bold
      >
        <template #mds-section-title>
          <span>Bulk Import</span>
        </template>
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="flat"
              icon="download"
              text="Template"
              href="/files/providers-template.csv"
              download
            />
          </mds-button-container>
        </template>
        <mds-form @submit.native.prevent>
          <mds-input
            label="File"
            hidden-label
            type="file"
            accept=".csv"
            @change="setFile"
          />
          <mds-button-container right-aligned>
            <mds-button
              variation="flat"
              type="button"
              text="Get Report"
              :disabled="!url"
              :href="url"
              :download="`import-report.csv`"
            />
            <mds-button
              variation="primary"
              type="submit"
              :disabled="!file"
              :loading="isReportLoading"
              text="Submit"
              @click="uploadFile"
            />
          </mds-button-container>
        </mds-form>
      </mds-section>
    </mds-modal>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import StatusTag from '@/components/ui/StatusTag';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import MdsSection from '@mds/section';
import MdsModal from '@mds/modal';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { TABLE_HEADERS } from '@/constants/providers.constant';
import { MODULE, COMMON } from '@/constants/store.constant';
import ROUTE from '@/constants/routes.constant';
import { postBatchProviders } from '@/services/api/provider.service';

export default {
  name: 'TableSection',
  components: {
    StoreProvider,
    StatusTag,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsForm,
    MdsInput,
    MdsSection,
  },
  data () {
    return {
      toggle: false,
      file: null,
      isReportLoading: false,
      url: null,
    };
  },
  methods: {
    setFile ({ target: { files: [file] } }) {
      this.file = file;
    },
    async uploadFile () {
      const formData = new FormData();
      formData.append('file', this.file);

      this.isReportLoading = true;
      const { data: result } = await postBatchProviders(formData);

      this.url = URL.createObjectURL(new Blob([result]));
      this.isReportLoading = false;
    },
  },
  storeInfo: {
    module: MODULE.PROVIDERS,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
  headers: TABLE_HEADERS,
  route: ROUTE,
};
</script>
