export const TABLE_HEADERS = [
  {
    fieldName: 'name',
    text: 'Name',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'documentGroup',
    text: 'Document Group',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'externalProfileId',
    text: 'Profile Uuid',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'comment',
    text: 'Comment',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'emUrl',
    text: 'Url',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'active',
    text: 'Status',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '5%',
    },
  }
];
