<template>
  <div>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col :cols="4">
        <mds-search-field
          v-model="filterText"
          :placeholder="this.$t(`collectionPageEM.filterLabel`)"
          class="filter__search"
          label="Search"
        />
      </mds-col>
      <mds-col
        :cols="6"
        class="filter__status"
      >
        <mds-fieldset
          horizontal
          class="filter__fieldset"
        >
          <span class="filter__label">{{ this.$t(`collectionPageEM.lastExecutionDate`) }}</span>
          <mds-date-picker
            class="filter__startdate"
            :value="startDate"
            :min-max-dates="minMaxDates"
            :label="this.$t(`collectionPageEM.startDateLabel`)"
            @input="updateCurrentDate"
          />

          <mds-date-picker
            v-model="endDate"
            class="filter__enddate"
            :min-max-dates="minMaxEndDates"
            :disable-dates="disableDates"
            :label="this.$t(`collectionPageEM.endDateLabel`)"
          />
          <mds-button
            variation="primary"
            :text="this.$t(`collectionPageEM.searchButton`)"
            class="filter__button"
            @click="applyFilters"
          />
        </mds-fieldset>
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import { MdsButton } from '@mds/button';
import MdsDatePicker from '@mds/date-picker';
import MdsFieldset from '@mds/fieldset';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';

const minMaxDates = {
  min: new Date(2015, 0, 1),
  max: new Date(2025, 8, 15),
};

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButton,
    MdsDatePicker,
    MdsFieldset,
  },
  data () {
    return {
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true, disabled: true },
        { id: '1', text: 'Active', value: true, active: false, disabled: true },
        { id: '2', text: 'Inactive', value: false, active: false, disabled: true }
      ],
      minMaxDates,
      minMaxEndDates: {
        ...minMaxDates,
      },
      disableDates: () => false,
    };
  },
  computed: {
    filterText: {
      get: function () {
        return this.$store.getters[`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.GET_STATE_BY_NAME}`]('filterText') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.UPDATE_STATE}`, { name: 'filterText', value: newValue });
        this.$store.dispatch(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.FILTER_DATA}`);
      },
    },
    startDate: {
      get: function () {
        return this.$store.getters[`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.GET_STATE_BY_NAME}`]('startDate');
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.UPDATE_STATE}`, { name: 'startDate', value: newValue });
      },
    },
    endDate: {
      get: function () {
        return this.$store.getters[`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.GET_STATE_BY_NAME}`]('endDate');
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.UPDATE_STATE}`, { name: 'endDate', value: newValue });
      },
    },
  },
  methods: {
    fetchEMCollection: debounce(async function () {
      this.$store.commit(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.UPDATE_PARAM}`, { name: 'name', value: this.filterText });
      await this.$store.dispatch(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.FILTER_DATA}`, 'name');
    }, 500),
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.$store.commit(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.UPDATE_PARAM}`, { name: 'active', value: item.value });
          this.$store.dispatch(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.FILTER_DATA}`, 'active');
        };
      });
    },
    checkValidDate (date) {
      return (
        !!date &&
            typeof date.getTime === 'function' &&
            date.toString() !== 'Invalid Date'
      );
    },
    updateCurrentDate (value) {
      this.startDate = value;
      this.endDate = '';

      if (this.checkValidDate(value)) {
        this.disableDates = (date) => date.getTime() <= value.getTime();
        this.minMaxEndDates.min = value;
      } else {
        this.disableDates = () => false;
      }
    },
    applyFilters () {
      this.$store.dispatch(`${MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE}/${COMMON.FILTER_DATA}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
.filter {
  &__search {
    width: 280px;
    margin-top: $mds-space-3-x;
  }
  &__button {
    margin: $mds-space-2-and-a-half-x 0 0 $mds-space-2-x;
    display: block;
    max-width: 90px;
  }
  &__fieldset {
    border: solid 1px #e5e5e5;
    border-radius: $mds-space-quarter-x;
    box-sizing: border-box;
    position: relative;
    padding: $mds-space-4-x $mds-space-2-x $mds-space-2-x;
  }
  &__enddate {
    margin-left: $mds-space-2-and-a-half-x;
  }
  &__label {
    @include mds-body-text-m();
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.67px;
    line-height: $mds-space-1-and-a-half-x;
    text-transform: uppercase;
    color: #1e1e1e;
    padding: $mds-space-quarter-x $mds-space-half-x;
    position: absolute;
    top: $mds-space-1-x;
  }
}
</style>
