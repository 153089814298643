export const TABLE_HEADERS = [
  {
    fieldName: 'companyId',
    text: 'Company Id',
    style: {
      width: '130px',
    },
  },
  {
    fieldName: 'translatedName',
    text: 'Name',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'homePage',
    text: 'Website',
    style: {
      width: '40%',
    },
  },
  {
    fieldName: 'links',
    text: 'Active Links',
    style: {
      width: '130px',
    },
  },
  {
    fieldName: 'enabled',
    text: 'Status',
    style: {
      width: '130px',
    },
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '130px',
    },
  }
];
